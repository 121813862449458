import PropTypes from "prop-types"
import React from "react"
import { Link, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const ArticleCard = ({ article }) => {
  return (
    <Link
      to={`/blog/${article.slug}`}
      className="flex flex-col justify-start overflow-hidden"
    >
      <div className="hover:z-1 flex-shrink-0 overflow-hidden lg:h-[640px] lg:w-[640px]">
        <GatsbyImage
          className="flex-shrink-0 duration-1000 ease-in-out hover:scale-125 hover:transform"
          image={getImage(
            article.coverThumbnail?.localFile || article.cover?.localFile
          )}
          alt={article.coverThumbnail?.alternativeText || ""}
        />
      </div>
      <div className="flex h-full w-full flex-col justify-between py-4 lg:w-2/3">
        <h3 className="line-clamp-2 text-2xl font-bold" title={article.title}>
          {article.title}
        </h3>
        <div className="mt-2 font-semibold text-brand-primary">
          {article.createdAtFromNow}
        </div>
      </div>
    </Link>
  )
}

ArticleCard.propTypes = {
  article: PropTypes.shape({
    cover: PropTypes.shape({
      alternativeText: PropTypes.string,
      localFile: PropTypes.any,
    }),
    coverThumbnail: PropTypes.shape({
      alternativeText: PropTypes.string,
      localFile: PropTypes.any,
    }),
    createdAtFromNow: PropTypes.string,
    slug: PropTypes.string,
    title: PropTypes.string,
  }),
}

export const query = graphql`
  fragment ArticleCard on STRAPI_ARTICLE {
    id
    slug
    title
    subtitle
    description
    createdAtFromNow: createdAt(fromNow: true, locale: "ES")
    createdAt(formatString: "DD [de] MMMM[, ]YYYY", locale: "ES")
    coverThumbnail {
      alternativeText
      localFile {
        childImageSharp {
          gatsbyImageData(aspectRatio: 1, height: 640, quality: 95)
        }
      }
    }
    cover {
      alternativeText
      firstArticle: localFile {
        childImageSharp {
          gatsbyImageData(aspectRatio: 1.77, quality: 95)
        }
      }
      localFile {
        childImageSharp {
          gatsbyImageData(
            aspectRatio: 1.77
            transformOptions: { cropFocus: CENTER }
            height: 670
          )
        }
      }
    }
  }
`

export default ArticleCard
