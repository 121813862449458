import React from "react"
import PropTypes from "prop-types"
import ArticleCard from "./article-card"
import ArticleFirstCard from "./article-first-card"

const ArticlesGrid = ({ articles }) => {
  return (
    <>
      {articles[0] && <ArticleFirstCard article={articles[0]} />}
      <div className="mt-10 grid grid-cols-1 gap-4 lg:grid-cols-2">
        {articles.slice(1).map((article) => (
          <ArticleCard article={article} key={article.id} />
        ))}
      </div>
    </>
  )
}

ArticlesGrid.propTypes = {
  articles: PropTypes.array.isRequired,
}

export default ArticlesGrid
