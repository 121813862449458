import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { classNames } from "../utilities"

const ArticleFirstCard = ({ article, className }) => {
  return (
    <Link
      to={`/blog/${article.slug}`}
      className={classNames(
        "flex w-full flex-col justify-start overflow-hidden",
        className
      )}
    >
      <GatsbyImage
        className="flex-shrink-0"
        image={getImage(article.cover?.firstArticle)}
        alt={article.cover?.alternativeText || ""}
      />
      <div className="flex h-full w-full flex-col items-center justify-center py-4">
        <h3
          className="w-full text-center text-2xl  font-bold md:text-5xl lg:w-2/3"
          title={article.title}
        >
          {article.title}
        </h3>
        <div className="mt-2 font-semibold text-brand-primary">
          {article.createdAtFromNow}
        </div>
      </div>
    </Link>
  )
}

ArticleFirstCard.propTypes = {
  article: PropTypes.shape({
    cover: PropTypes.shape({
      alternativeText: PropTypes.string,
      localFile: PropTypes.any,
    }),
    createdAtFromNow: PropTypes.string,
    slug: PropTypes.string,
    title: PropTypes.string,
  }),
  className: PropTypes.string,
}
export default ArticleFirstCard
